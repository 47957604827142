<template>
  <a
    href="#"
    :class="{
      'bg-primary-50 dark:bg-primary-950 hover:bg-primary-100 hover:dark:bg-green-800': !notification.date_read,
      'hover:bg-surface-100 hover:dark:bg-surface-800': notification.date_read,
    }"
    class="py-2 px-4 flex gap-2 cursor-pointer hover:text-primary"
    @click="onClick"
  >
    <div class="flex-grow space-y-2">
      <span class="block font-semibold text-surface-700 dark:text-surface-300">{{ notification.title }}</span>
      <span class="text-sm text-surface-500">{{ notification.content }}</span>
      <span class="block text-primary-400 text-xs dark:text-surface-600">
        <span>{{ formatDateAgo(notification.date_creation) }}</span>
      </span>
    </div>
    <div class="flex-none">
      <Button
        icon="pi pi-ellipsis-h"
        rounded
        severity="secondary"
        @click="toggleOptions"
      />
      <Menu
        ref="optionsMenu"
        :model="menuOptions"
        :popup="true"
      />
    </div>
  </a>
</template>

<script setup lang="ts">
import type Menu from 'primevue/menu'
import type {MenuItem} from 'primevue/menuitem'

const emit = defineEmits<{
  delete: [],
}>()
const props = defineProps<{
  notification: NotificationInstance
}>()

const notification = toRef(props, 'notification')
const optionsMenu = ref<typeof Menu>()

const menuOptions = computed<MenuItem[]>(() => {
  const options = []

  if (notification.value.date_read) {
    options.push({
      label: 'Mark unread',
      icon: 'pi pi-check',
      command: () => markUnread()
    })
  } else {
    options.push({
      label: 'Mark read',
      icon: 'pi pi-check',
      command: () => markRead()
    })
  }

  options.push({
    label: 'Delete notification',
    icon: 'pi pi-trash',
    command: () => deleteInstance()
  })

  return options
})

const deleteInstance = async () => {
  const { $api } = useNuxtApp()
  await $api<NotificationInstance>(`/notifications/${notification.value.id}/`, {
    method: 'DELETE'
  })
    .then(() => {
      emit('delete')
    })
}

const onClick = async () => {
  await markRead()
  navigateTo(notification.value.link)
}

const markRead = async () => {
  if (notification.value.date_read) return

  const { $api } = useNuxtApp()
  await $api<NotificationInstance>(`/notifications/${notification.value.id}/mark-read/`, {
    method: 'POST'
  })
    .then(response => notification.value.date_read = response.date_read)
}

const markUnread = async () => {
  if (!notification.value.date_read) return

  const { $api } = useNuxtApp()
  await $api<NotificationInstance>(`/notifications/${notification.value.id}/mark-unread/`, {
    method: 'POST'
  })
    .then(response => notification.value.date_read = response.date_read)
}

const toggleOptions = (event: Event) => {
  optionsMenu.value?.toggle(event)
}
</script>
