<template>
  <div class="flex items-center justify-between px-4 py-6">
    <SiteLogo class="h-[36px]" dark />

    <Button
      class="lg:hidden"
      icon="pi pi-arrow-left"
      link
      @click="emit('closeSidebar')"
    />
  </div>

  <div class="duration-300 ease-linear flex flex-1 flex-col no-scrollbar overflow-y-auto">
    <nav class="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
      <div v-for="menu in navItems" class="mb-5">
        <h5 class="font-bold mb-2 px-5 text-gray-500 uppercase">{{ menu.label }}</h5>
        <PanelMenu
          :model="menu.items"
          class="border-none bg-surface-900 dark:bg-surface-900"
        >
        </PanelMenu>
      </div>

    </nav>
  </div>

  <div class="drawer-side">
    <div class="flex flex-col min-h-full p-4 text-white">
      <div class="flex-none py-2 text-center text-xs">
        <div class="space-x-3">
          <NuxtLink
            :to="{
              name: 'docs-privacy-policy'
            }"
          >
            Privacy Policy
          </NuxtLink>

          <NuxtLink
            :to="{
              name: 'docs-terms-of-use'
            }"
          >
            Terms of Service
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MenuItem } from 'primevue/menuitem'
import type { RouteLocationRaw } from 'vue-router'

const { currentCompany } = useCompany()
const emit = defineEmits<{
  closeSidebar: []
}>()
const { logout } = useAuth()



const goTo = (params: RouteLocationRaw) => {
  navigateTo(params)
  emit('closeSidebar')
}
</script>

<style>
@reference 'tailwindcss';

:deep(.p-panelmenu-panel) {
  @apply bg-transparent !border-none !shadow-none;
}

:deep(.p-panelmenu-header-link) {
  @apply text-white;
}

:deep(.p-panelmenu-item-label) {
  @apply text-white
}
</style>
